<template>
    <div class="">
      <v-btn small  class="mr-1 border-on shadow-off bg-white" style="border-radius: 8px !important; padding-top: 21px !important;
      padding-bottom: 21px !important; background:white" @click="uploaddata = []; file = null; dialog = true">
        <v-icon class="mr-1">mdi-upload-multiple</v-icon>
        <span>Bulk Task Upload</span>
      </v-btn>
      <lb-dailogboxnew v-model="dialog" :width="(uploaddata.length > 0) ? '' : '400'"
        heading="Bulk Tasklist Upload" :loading="loading">
        <template v-slot:body>
          <div>
            <div class="text-center mb-1">Upload the bulk upload tasklist template here.</div>
            <div class="d-flex align-center justify-center">
              <lb-file v-model="file" label="" class="py-0 my-0" :loading="fileprocessing['file'] === 1"
                :drag="false" hidedetails @change="readFile('file')" :displayname="false"
                accept=".xlsx,.xls,.xlsm,.xlsb" />
              <v-btn color="fbcPrimary" small class="ml-2 white--text"
                @click="downloadTemplate()">Download Template</v-btn>
            </div>
          </div>        
          <div v-if="uploaddata.length > 0" class="mt-3">
            <v-simple-table dense class="FC-Table">
              <template v-slot:default>
                <thead>
                  <th class="text-left" scope="description">Description</th>
                  <th class="text-left" scope="category">Category</th>
                  <th class="text-left" scope="subcategory">Sub Category</th>
                  <th class="text-left" scope="location">Location</th>
                  <th class="text-left" scope="assertions">Assertions</th>
                  <th class="text-left" scope="purpose">Purpose</th>
                  <th class="text-left" scope="instruction">Instruction</th>
                  <th class="text-left" scope="preparer">Preparer</th>
                  <th class="text-left" scope="approver">Approver</th>
                  <th v-if="!project" class="text-left" scope="tags">Tags</th>
                  <th class="text-left" scope="duedays">Due days</th>
                  <th class="text-left" scope="glcode">GL Code</th>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v, k) in uploaddata" :key="k" class="">
                    <template v-for="(vv, kk) in v">
                      <td v-if="dataerror[k][kk]" :key="kk" class="error--text font-weight-bold">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-if="kk==='assertions' || kk === 'preparer' || kk === 'approver' || kk === 'tags' || kk === 'location'" class="d-inline-block">
                              <v-chip v-for="(va, ka) in vv" :key="ka" v-bind="attrs" v-on="on" color="error" small outlined
                                class="pl-2 mb-1 mr-1">
                                <v-icon v-if="kk==='tags'">mdi-tag</v-icon>
                                <v-icon v-else-if="kk !== 'assertions'">mdi-account</v-icon>
                                <v-icon v-else>mdi-check</v-icon>
                                <span class="pl-1">{{ va }}</span>
                              </v-chip>
                            </div>
                            <div v-else-if="kk === 'glcode'">
                                <v-chip v-for="(va, ka) in vv" :key="ka" v-bind="attrs" v-on="on" color="error" small outlined
                                  class="pl-2 mb-1 mr-1">
                                  <span class="pl-1">{{ va }}</span>
                                </v-chip>
                            </div>
                            <span v-else v-bind="attrs" v-on="on">{{ dataerror[k][kk] }} || Error</span>
                          </template>
                        </v-tooltip>
                      </td>
                      <td v-else :key="kk">
                        <div v-if="kk==='assertions' || kk === 'preparer' || kk === 'approver' || kk === 'tags' || kk==='location'">
                          <v-chip v-for="(va, ka) in vv" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                            <v-icon v-if="kk==='tags'">mdi-tag</v-icon>
                            <v-icon v-if="kk === 'location'">mdi-map-marker</v-icon>
                            <v-icon v-else-if="kk !== 'assertions'">mdi-account</v-icon>
                            <v-icon v-else>mdi-check</v-icon>
                            <span class="pl-1">{{ va }}</span>
                          </v-chip>
                        </div>
                        <div v-else-if=" kk === 'glcode'">
                          <v-chip v-for="(va, ka) in vv" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                              <span class="pl-1">{{ va}}</span>
                            </v-chip>
                        </div>
                        <div v-else>{{ vv }}</div>
                      </td>
                    </template>
                  </tr>
                </v-slide-y-transition>
              </template>
            </v-simple-table>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <div v-if="uploaddata.length > 0">
            <v-btn small class="ml-2" color="fbcPrimary" v-if="!flag" @click="uploadData()">Verify &
              Upload</v-btn>
            <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload
            </div>
          </div>
        </template>
      </lb-dailogboxnew>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'financialclose_component_checklist_bulkupload',
    // props: ['props'],
    data: function () {
      return {
        right: "financialclose_master_checklist",
        // generateddescription: "",
        file: null,
        fileprocessing: {},
        flag: false,
        uploaddata: [],
        dataerror: [],
        dialog: false,
        loading: false,
      }
    },
    created() {
    },
    activated() {
    },
    props: {
      project: {
        type: String,
      },
    },
    methods: {
      init() {
      },
      downloadTemplate(){
        if(this.project) this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/getbulkchecklisttemplate');
        else this.$nova.downloadFile('', {}, '/v2/financialclose/masters/checklist/getbulksubmittemplate');
      },
      readFile(key) {
        let file = this.file;
        if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
          let headings = ['Description', 'Category', 'Sub Category','Location', 'Assertions', 'Purpose', 'Instruction', 'Preparer', 'Approver', 'Tags', 'Due Days', 'GL Code'];
          // let possibleassertions = ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'];
          this.loading = true;
          this.fileprocessing[key] = 1;
          let ext = ((file || {}).name || "").split(".").pop();
          Promise.resolve().then(() => {
            if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
            return this.$nova.readExcelFile(file, "TOP SHEET");
          }).then(async (dt) => {
            if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
              let filedata = [];
              let errordata = [];
              let chartofaccountData = [];
              this.flag = false;
              await this.axios.post("/v2/financialclose/masters/chartofaccount/get")
                .then((dt) => {
                  if (dt.data.status === "success") {
                    chartofaccountData.push({ "data": dt.data.data })
                  }
                });
              for (let i = 0; i < dt.length; i++) {
                if (i > 0 && dt[i].length>0) {
                  const el = dt[i];
                  let rowdata = { "description": el[0], "category": el[1], "subcategory": el[2]} 
                  rowdata.location = (el[3] || "base").split(",").filter(x => x.replaceAll(/\s/g, ''));
                  rowdata.assertions = (el[4] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                  rowdata.purpose = el[5]
                  rowdata.instruction = el[6] 
                  rowdata.preparer = (el[7] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                  rowdata.approver = (el[8] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                  rowdata.tags = (el[9] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                  rowdata.duedays = Number(el[10] || "") || null;
                  rowdata.glcode = (el[11] || "").toString().split(",").filter(x => x.replaceAll(/\s/g, ''));
                  let errors = {};
                  if ((rowdata.description || "").toString() === "") errors["description"] = "Description is required";
                  if ((rowdata.category || "").toString() === "") errors["category"] = "Category is required";
                  if ((rowdata.subcategory || "").toString() === "") errors["subcategory"] = "Sub Category is required";
                  if(this.project) delete rowdata.tags;
                  else if ((rowdata.tags || []).length === 0) errors["tags"] = "Tags is required";
                  // let notfoundassertions = [];
                  // for (const i of rowdata.assertions) {
                  //   if(possibleassertions.indexOf(i) === -1) notfoundassertions.push(i);
                  // }
                  // if(notfoundassertions.length > 0) errors["assertions"] = notfoundassertions.join(", ") + " assertions not found";
                  if (rowdata.preparer.length > 0 && rowdata.approver.length > 0) {
                    let preparers = rowdata.preparer;
                    let approvers = rowdata.approver;
                    let duplicates = [];
                    for (const i of preparers) {
                      if (approvers.indexOf(i) > -1) duplicates.push(i);
                    }
                    if (duplicates.length > 0) errors["preparer"] = "'" + duplicates.join(",") + "' cannot be both preparer and approver";
                  }
                  // if(rowdata.location.length===0) errors["location"]="Location is required"
                  for(const i of rowdata.glcode){
                    if (rowdata.glcode[i] != null && rowdata.glcode[i] !== "") {
                      let isGLValid = chartofaccountData[0].data.filter(obj => obj['glcode'] == rowdata.glcode[i]) || "";
                      if (isGLValid === "" || isGLValid.length === 0) {
                        errors["glcode"] = rowdata.glcode[i] + " Invalid GL Code";
                      }
                    }
                  }
                  if (Object.keys(errors).length > 0) this.flag = true;
                  filedata.push(rowdata);
                  errordata.push(errors);
                }
              }
              this.uploaddata = filedata;
              this.dataerror = errordata;
              this.dialog = true;
            } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
          }).catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          }).finally(() => {
            this.file = null;
            this.fileprocessing[key] = 0;
            this.loading = false;
          });
        }
      },
      uploadData() {
        this.loading = true;
        let url = "/v2/financialclose/masters/checklist/bulkadd";
        if(this.project) url = "/v2/financialclose/analysis/bulkaddchecklist/"+this.project;
        this.axios.post(url, { data: this.uploaddata }).then(dt => {
          if (dt.data.status === "success") {
            this.dialog = false;
            this.$emit('reload');
            this.$store.commit("sbSuccess", "Checklist uploaded successfully");
          } else throw dt.data.message || "Error uploading Tasklist";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
      },
    },
    watch: {
    }
  }
  </script>
  